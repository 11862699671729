<template>
  <div class="order">
    <van-sticky :offset-top="0">
    <van-search
      v-model="keywords"
      show-action
      placeholder="请输入搜索关键词"
      @click="onSearch"
      shape="round"
      readonly
      :class="memberid <= 0 ? 'pf16': ''"
    >
      <template #left>
        <van-icon v-if="memberid > 0" name="arrow-left" @click="backurl" />
      </template>
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    </van-sticky>
    <van-tabs v-model="active" swipe-threshold="6" @click="onClickTabs" sticky offset-top="54">
      <van-tab v-for="(v,i) in tabls" :key="i" :title="v.title" :name="v.name">
        <div v-if="!nodata">
          <van-list
            v-model="loadingS"
            :finished="finished"
            :finished-text="nodata ? '' : '没有更多了'"
            :immediate-check="false"
            @load="onLoad"
          >
            <div class="order_list" v-for="(item, index) in list" :key="index" >
              <van-cell :border="false" center class="userinfo" @click="details(item.id)">
                <template #icon>
                  <div>
                    <van-image
                      width="20px"
                      height="20px"
                      lazy-load
                      round
                      fit="cover"
                      style="display: block;margin-right: 5px;"
                      :src="item.avatar ? item.avatar : 'https://dk.midbest.cn/img/head.ede6594c.png'"
                    >
                      <template v-slot:loading>
                        <van-loading type="spinner" size="20" />
                      </template>
                    </van-image>
                  </div>
                </template>
                <template #title>
                  <div class="van-ellipsis">{{item.nickname ? item.nickname : '未设置昵称'}}</div>
                </template>
                <template #right-icon>
                  <van-button size="mini" plain type="warning" style="margin-right: 5px; height: 18px;" @click.stop="copay(item.agent_code)">{{item.agent_code}}</van-button>
                  <span style="margin-right: 10px;">{{item.ordersn}}</span>
                  <van-button size="mini" @click.stop="copay(item.ordersn)">复制</van-button>
                </template>
              </van-cell>
              <van-card
                v-for="(val, key) in item.goods"
                :key="key"
                :num="val.total"
                :price="val.realprice"
                :desc="val.optionname"
                :title="val.goodstitle"
                :thumb="val.goodsthumb"
                lazy-load
                @click="details(item.id)"
              >
              </van-card>
              <van-cell center @click="details(item.id)">
                <template #icon><span class="red">{{item.status_str}}</span></template>
                <template #title>
                  <div class="order_total">
                    <span>实付：&yen;{{item.price}}</span>
                    <span v-if="item.dispatchprice && parseFloat(item.dispatchprice) > 0">(含运费：&yen;{{item.dispatchprice}})</span>
                    <span v-else>(免运费)</span>
                  </div>
                </template>
              </van-cell>
              <van-cell center @click="details(item.id)">
                <template #title>
                  <div class="order_total">
                    <span class="iconme" v-if="item.ydzg">{{item.ydzg}}</span>
                    <span class="iconme">Y ({{item.agentcode1}})：&yen;{{item.commissions1_total}}</span>
                    <span class="iconme" v-if="item.commissions2 > 0">Y ({{item.agentcode2}})：&yen;{{item.commissions2_total}}</span>
                    <span class="iconme">C ({{item.shop_code}})：&yen;{{item.iconme}}</span>
                  </div>
                </template>
              </van-cell>
              <div class="order_footer" v-if="item.is_service_btn || item.is_service_btn2">
                <van-button type="default" block v-if="item.is_service_btn" @click="refund(item.id, item.status, item.is_outorder, item.check_status)">{{item.is_service_btn}}</van-button>

                <van-button type="default" block v-if="item.is_service_btn2" @click="cancelRefund(item.id)">{{item.is_service_btn2}}</van-button>
              </div>
            </div>
          </van-list>
        </div>
        <div v-if="nodata">
          <van-empty description="暂无订单" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  name: "Order",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      keywords: "", // 搜索关键词
      active: 0, // 全部订单
      tabls: [
        {title: '全部', index: 0, name: 0},
        {title: '售后订单', index: 7, name: 7},
        {title: '待付款', index: 1, name: 1},
        {title: '待发货', index: 2, name: 2},
        {title: '待收货', index: 3, name: 3},
        {title: '已完成', index: 4, name: 4},
        {title: '已关闭', index: 5, name: 5},
      ],
      list: [],
      currOffset: 0,
      currSize: 10,
      finished: false,
      nodata: false, // 默认有数据
      loadingS: false,
      memberid: 0, // 会员id
      shopid: 0,
      xcx: 0,
    }
  },
  mounted() {
    this.xcx = this.$route.query.xcx ? parseInt(this.$route.query.xcx) : 0;
    this.memberid = this.$route.query.memberid ? parseInt(this.$route.query.memberid) : 0;
    this.shopid = localStorage.getItem("SHOP_MID") ? localStorage.getItem("SHOP_MID") : 0;
    this.onLoad()
  },
  activated() {
    this.xcx = this.$route.query.xcx ? parseInt(this.$route.query.xcx) : 0;
    this.shopid = localStorage.getItem("SHOP_MID") ? localStorage.getItem("SHOP_MID") : 0;
    // this.list = [];
    // this.orderlist = [];
    // this.currOffset[this.active] = 0;
    
    // console.log("activated");
    // this.onLoad()
  },
  methods: {
    onSearch() {
      this.$router.push({
        name: "OrderSearch",
        query: {
          xcx: this.xcx,
          t: new Date().getTime()
        }
      });
    },
    // 获取订单列表
    onLoad() {

      /* 页面参数提交给小程序 */
      if (this.xcx == 1 && this.shopid != 1720 && this.shopid != 696) {
        window.wx.miniProgram.getEnv(function(res) {
          window.wx.miniProgram.navigateTo({url: '/pages3/shop/order/lists/lists'});
        });
      }

      let that = null;
      that = this;
      this.loadingS = true;
      this.nodata = false;
      this.finishedS = false;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/order/get_list",
          that.$qs.stringify({
            active: that.active,
            size: that.currSize,
            offset: that.currOffset,
            memberid: that.memberid
          })
        )
        .then(res => {
          console.log(res);
          that.loadingS = false;
          if (res.data.code == 100000) {
            if (that.currOffset == 0) {
              that.list = res.data.data;
            }else {
              if (res.data.data) {
                res.data.data.forEach(item => {
                  that.list.push(item);
                })
              }
            }
            if (res.data.data.length >= that.currSize) {
              that.currOffset += that.currSize;
              that.finished = false;
            }else {
              that.currOffset += res.data.data.length;
              that.finished = true;
            }
          }else {
            that.loadingS = false;
            that.finished = true;
            that.finishedS = true;
            if (that.currOffset == 0) {
              that.nodata = true;
            }
          }
        })
        .catch(err => {
          console.log(err);
          that.loadingS = false;
          that.finished = true;
          that.finishedS = true;
          if (that.currOffset[that.active] == 0) {
            that.nodata = true;
          }
        })
    },
    // 复制订单号
    copay(ordersn) {
      let _that = null;
      _that = this;
      _that.$copyText(ordersn)
      .then(
        function(e) {
          _that.$toast("复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      )
    },
    // 切换列表
    onClickTabs(name, title) {
      console.log(name, title, this.active);
      console.log("onClickTabs");
      this.nodata = false;
      this.finished = false;
      this.currOffset = 0
      this.list = [];
      this.onLoad();
    },
    // 订单详情页
    details(id) {
      console.log(id);
      this.$router.push({
        name: "OrderDetail", 
        query: {
          id: id,
          xcx: this.xcx,
          t: new Date().getTime()
        }
      });
    },
    // 申请售后
    refund(id,status,orderOut,checkStatus) {
      if ((status==1 && (orderOut == 0 || orderOut==3)) || checkStatus==2) {
        this.$router.push({
          name: "serviceDetail", 
          query: {
            id: id,
            status: status,
            xcx: this.xcx,
            t: new Date().getTime()
          }
        });
      }else {
        this.$router.push({
          name: "RefundOrderOne", 
          query: {
            id: id,
            status: status,
            xcx: this.xcx,
            t: new Date().getTime()
          }
        });
      }
      
    },
    cancelRefund(id){
      this.$router.push({
        name: "serviceDetail", 
        query: {
          id: id,
          xcx: this.xcx,
          t: new Date().getTime()
        }
      });
    },
    backurl() {
      this.$router.back();
    }
  }
}
</script>
<style lang="less">
.order {
  background-color: #f0f0f0;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  &:last-child {
    padding-bottom: 50px;
  }
  .van-search {
    padding-left: 0;
    .van-icon-arrow-left {
      padding: 0 16px;
      line-height: 34px;
    }
    .van-search__action {
      padding: 0 16px;
    }
  }
  .pf16 {
    padding-left: 16px;
  }

  // 订单列表
  .order_list {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    margin: 10px 0;
    &:last-of-type {
      margin-bottom: 0;
    }
    .van-cell {
      .van-cell__title {
        flex: 1;
        text-align: left;
        overflow: hidden;
        .order_total {
          width: 100%;
          text-align: right;
          .iconme {
            margin-left: 10px;
            color: #ee0a24;
            border: 1px solid #ee0a24;
            border-radius: 2px;
            padding: 1px 5px;
            font-size: 10px;
          }
        }
      }
    }
    .userinfo .van-cell__title {
      margin-right: 5px;
    }
    .van-card {
      background-color: #fff;
      text-align: left;
      margin-top: 0;
    }
    .red {
      color: #ee0a24;
    }
  }
}
</style>